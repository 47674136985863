import React from "react";
import Back from "../common/back/Back";
import "./contact.css";
import Heading from "../common/heading/Heading";

const Contact = () => {
  const map =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d199318.99429116274!2d76.1341947!3d10.5120881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7ef243c32ac8f%3A0x6817f7c13404af5c!2s5th%20floor%2C%20Crown%20Towers%2C%20opposite%20Head%20Post%20Office%2C%20Sakthan%20Thampuran%20Nagar%2C%20Thrissur%2C%20Kerala%20680001!5e0!3m2!1sen!2sin!4v1680842379874!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ';
  return (
    <>
      <Back title="Contact us" />
      <section className="contacts padding">
        <Heading subtitle="Contact us" title="Frequently Asked Question" />

        <div style={{ marginBottom: 80 }} className="container shadow flexSB">
          <div className="left row">
            <iframe src={map}></iframe>
          </div>
          <div className="right row">
            <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>

            <div className="items grid2">
              <div className="box">
                <h4>ADDRESS:</h4>
                <p>
                  5th floor, Crown Tower, Opposite Head Post Office, Sakthan
                  Nagar, Thrissur 680001 31/18.
                  <br />
                  Fist Main Road, Balaji Nagar, Chennai 600032
                </p>
              </div>

              <div className="box">
                <h4>EMAIL:</h4>
                <p>accordacademytcr@gmail.com</p>
              </div>
              <div className="box">
                <h4>PHONE:</h4>
                <p> +91 9946822323, +91 9846234578</p>
              </div>
            </div>

            <form action="">
              <div className="flexSB">
                <input type="text" placeholder="Name" />
                <input type="email" placeholder="Email" />
              </div>
              <input type="text" placeholder="Subject" />
              <textarea cols="30" rows="10">
                Create a message here...
              </textarea>
              <button className="primary-btn">SEND MESSAGE</button>
            </form>

            {/* <h3>Follow us here</h3>
            <span>FACEBOOK TWITTER INSTAGRAM DRIBBBLE</span> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
