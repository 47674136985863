import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "../../Assets/about.webp";
import { homeAbout } from "../../dummydata";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: theme.shadows[8],
  },
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  height: "100%",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "transform 0.6s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
}));

const StatsBox = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(4),
  textAlign: "center",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const AboutCard = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.2,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box sx={{ bgcolor: "background.default", py: 8 }} ref={ref}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          {/* Left side - Image */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: { md: "calc(100vh - 100px)" },
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateX(0)" : "translateX(-50px)",
              transition: "all 0.6s ease-out",
            }}
          >
            <ImageWrapper>
              <img src={Image} alt="About Us" />
            </ImageWrapper>
          </Grid>

          {/* Right side - Content */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateX(0)" : "translateX(50px)",
              transition: "all 0.6s ease-out",
            }}
          >
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" color="primary" gutterBottom>
                Helping You Succeed
              </Typography>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                Strategized for competitive English proficiency
              </Typography>
            </Box>

            <Grid container spacing={3}>
              {homeAbout.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  sx={{
                    opacity: isVisible ? 1 : 0,
                    transform: isVisible ? "translateY(0)" : "translateY(20px)",
                    transition: `all 0.4s ease-out ${index * 0.1}s`,
                  }}
                >
                  <StyledCard>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={2}>
                          <img
                            src={item.cover}
                            alt={item.title}
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "8px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={9} sm={10}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ fontWeight: "medium" }}
                          >
                            {item.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.desc}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* Stats Section */}
        <Box sx={{ mt: 8 }}>
          <Grid container spacing={3}>
            {[
              { number: "3,000+", label: "Success Stories" },
              { number: "320+", label: "Expert Instructors" },
              { number: "1,000+", label: "Active Students" },
              { number: "98%", label: "Satisfaction Rate" },
            ].map((stat, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={index}
                sx={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? "translateY(0)" : "translateY(20px)",
                  transition: `all 0.4s ease-out ${0.2 + index * 0.1}s`,
                }}
              >
                <StatsBox elevation={3}>
                  <Typography
                    variant="h3"
                    component="div"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    {stat.number}
                  </Typography>
                  <Typography variant="h6">{stat.label}</Typography>
                </StatsBox>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutCard;
