import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  CircularProgress,
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";

const NewsletterDisplay = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    email: null,
  });

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    const token = localStorage.getItem("access_token");

    try {
      const response = await fetch(
        "https://api.accordenglish.in/inquiries/newsletter/subscribe/list",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch subscriptions");
      }
      const data = await response.json();
      setSubscriptions(data.subscriptions);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleUnsubscribeClick = (email) => {
    setConfirmDialog({
      open: true,
      email: email,
    });
  };

  const handleCloseDialog = () => {
    setConfirmDialog({
      open: false,
      email: null,
    });
  };

  const handleConfirmUnsubscribe = async () => {
    try {
      const response = await fetch(
        `https://api.accordenglish.in/inquiries/newsletter/unsubscribe?email=${encodeURIComponent(
          confirmDialog.email
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to unsubscribe");
      }

      // Update the local state to reflect the change
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.map((sub) =>
          sub.email === confirmDialog.email ? { ...sub, is_active: false } : sub
        )
      );

      // Show success message
      alert(`Successfully unsubscribed ${confirmDialog.email}`);
      handleCloseDialog();
    } catch (err) {
      setError(err.message);
      handleCloseDialog();
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{ display: "flex", justifyContent: "center", mt: 4 }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <EmailIcon sx={{ mr: 2, color: "primary.main" }} />
          <Typography variant="h5">Newsletter Subscriptions</Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Total Active Subscriptions:{" "}
            {subscriptions.filter((sub) => sub.is_active).length}
          </Typography>
        </Box>

        <List>
          {subscriptions.map((sub) => (
            <ListItem
              key={sub.id}
              sx={{
                mb: 1,
                bgcolor: "background.paper",
                borderRadius: 1,
                border: "1px solid",
                borderColor: "divider",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                gap: 1,
                padding: 2,
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1" component="div">
                    {sub.email}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    Subscribed: {formatDate(sub.subscribed_at)}
                  </Typography>
                }
              />
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Chip
                  label={sub.is_active ? "Active" : "Inactive"}
                  color={sub.is_active ? "success" : "error"}
                  size="small"
                  sx={{ minWidth: 80 }}
                />
                {sub.is_active && (
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    startIcon={<UnsubscribeIcon />}
                    onClick={() => handleUnsubscribeClick(sub.email)}
                  >
                    Unsubscribe
                  </Button>
                )}
              </Box>
            </ListItem>
          ))}
        </List>

        {/* Confirmation Dialog */}
        <Dialog open={confirmDialog.open} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Unsubscribe</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to unsubscribe {confirmDialog.email} from
              the newsletter?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmUnsubscribe} color="error">
              Unsubscribe
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default NewsletterDisplay;
