import React from "react";
import { useLocation } from "react-router-dom";

const Back = ({ title }) => {
  const location = useLocation();

  return (
    <>
      <section className="back"></section>
      <div className="margin-courses"></div>
    </>
  );
};

export default Back;
