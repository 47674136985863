import React, { useState } from "react";
import Swal from 'sweetalert2';
import "./footer.css";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    if (!email) return;

    setIsSubmitting(true);
    try {
      const response = await fetch('https://api.accordenglish.in/inquiries/newsletter/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        Swal.fire({
          title: 'Subscribed!',
          text: 'You have successfully subscribed to our newsletter',
          icon: 'success',
          confirmButtonColor: '#4CAF50',
          timer: 3000,
          timerProgressBar: true,
        });
        setEmail(''); // Clear the input
      } else {
        throw new Error('Failed to subscribe');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to subscribe. Please try again.',
        icon: 'error',
        confirmButtonColor: '#f44336',
        timer: 3000,
        timerProgressBar: true,
      });
      console.error('Newsletter subscription error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <section className="newsletter">
        <div className="container">
          <div className="newsletter-content">
            <div className="newsletter-text">
              <h1>Newsletter - Stay tune and get the latest update</h1>
              <span>Far far away, behind the word mountains</span>
            </div>
            <form onSubmit={handleNewsletterSubmit} className="newsletter-form">
              <input
                type="email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button 
                type="submit" 
                className="submit-btn"
                disabled={isSubmitting}
                style={{ cursor: isSubmitting ? 'not-allowed' : 'pointer' }}
              >
                <i className={`fa ${isSubmitting ? 'fa-spinner fa-spin' : 'fa-paper-plane'}`}></i>
              </button>
            </form>
          </div>
        </div>
      </section>

      <footer>
        <div className="footer-content container">
          <div className="footer-box brand">
            <h1>About Accord</h1>
            <p>
              At Accord English we specialise in delivering quality English and
              German language training to students and clients globally. We
              provide on premise trainings at Thrissur and Chennai and online
              training for students across the world.
            </p>
            <div className="social-icons">
              <a href="#" className="icon">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="icon">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="icon">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          <div className="footer-box links">
            <h3>Explore</h3>
            <ul>
              <li>
                <a href="#">About Us</a>
              </li>
              <li>
                <a href="#">Services</a>
              </li>
              <li>
                <a href="#">Courses</a>
              </li>
              <li>
                <a href="#">Contact us</a>
              </li>
            </ul>
          </div>

          <div className="footer-box contact">
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className="fa fa-map"></i>
                <span>
                  5th floor, Crown Tower, Opposite Head Post Office, Sakthan
                  Nagar, Thrissur 680001
                </span>
              </li>
              <li>
                <i className="fa fa-map"></i>
                <span>31/18, Fist Main Road, Balaji Nagar, Chennai 600032</span>
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                <a href="tel:+23923929210">+91 9946822323, +91 9846234578</a>
              </li>
              <li>
                <i className="fa fa-paper-plane"></i>
                <a href="mailto:accordacademytcr@gmail.com">
                  accordacademytcr@gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;