import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import EnquiryPopup from "../../components/common/header/EnquiryPopup";
import Image from "../../Assets/image1.jpeg";
import { useNavigate } from "react-router-dom";

const CourseCarousel = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [visibleSlides, setVisibleSlides] = useState(4);
  const [isLoading, setIsLoading] = useState(true);
  const [openEnquiry, setOpenEnquiry] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState("");

  const handleEnrollClick = (courseName) => {
    setSelectedCourse(courseName);
    setOpenEnquiry(true);
  };
  const handleCloseEnquiry = () => {
    setOpenEnquiry(false);
  };
  const handleCourseClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  // Fetch courses data
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch("https://api.accordenglish.in/courses/");
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const data = await response.json();
        setCourses(data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchCourses();
  }, []);

  // Handle responsive design
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setVisibleSlides(1);
      } else if (window.innerWidth < 1024) {
        setVisibleSlides(2);
      } else {
        setVisibleSlides(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Auto-play functionality
  useEffect(() => {
    let interval;
    if (isAutoPlaying && courses.length > 0) {
      interval = setInterval(() => {
        setCurrentIndex((prev) =>
          prev === courses.length - visibleSlides ? 0 : prev + 1
        );
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlaying, courses.length, visibleSlides]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-xl">Loading courses...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-xl text-red-500">Error: {error}</div>
      </div>
    );
  }

  const maxIndex = Math.max(0, courses.length - visibleSlides);

  const nextSlide = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, maxIndex));
    setIsAutoPlaying(false);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
    setIsAutoPlaying(false);
  };

  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold mb-2">Our Courses</h2>
        <p className="text-xl text-gray-600">Popular Language Courses</p>
      </div>

      <div className="relative">
        {currentIndex > 0 && (
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 bg-white p-2 rounded-full shadow-lg z-10"
            aria-label="Previous slide"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
        )}

        <div className="overflow-hidden">
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${
                (currentIndex * 100) / visibleSlides
              }%)`,
            }}
          >
            {courses.map((course) => (
              <div
                onClick={() => handleCourseClick(course.id)}
                key={course.id}
                className="flex-none w-full sm:w-1/2 lg:w-1/3 p-4"
                style={{ flex: `0 0 ${100 / visibleSlides}%` }}
              >
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                  <img
                    src={Image}
                    alt={course.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-2">
                      {course.title}
                    </h3>
                    <p className="text-gray-600 mb-4">{course.subtitle}</p>
                    <p className="text-sm text-gray-500 mb-4">
                      {course.short_description}
                    </p>

                    <button
                      onClick={() => handleEnrollClick(course.title)}
                      className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
                    >
                      Enroll Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {currentIndex < maxIndex && (
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 bg-white p-2 rounded-full shadow-lg z-10"
            aria-label="Next slide"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        )}
      </div>

      <div className="flex justify-center mt-4 space-x-2">
        {[...Array(maxIndex + 1)].map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full transition-colors ${
              index === currentIndex ? "bg-blue-600" : "bg-gray-300"
            }`}
            onClick={() => {
              setCurrentIndex(index);
              setIsAutoPlaying(false);
            }}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>

      <EnquiryPopup
        open={openEnquiry}
        handleClose={handleCloseEnquiry}
        selectedCourse={selectedCourse}
      />
    </div>
  );
};

export default CourseCarousel;
