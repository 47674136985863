import "./App.css";
import Header from "./components/common/header/Header";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import About from "./components/about/About";
import CourseHome from "./components/allcourses/CourseHome";
import Team from "./components/team/Team";
import Pricing from "./components/pricing/Pricing";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import CourseDetail from "./components/allcourses/CourseDetail";
import AdminLogin from "./components/Admin/Login";
import AdminLayout from "./components/Admin/AdminLayout";
import Users from "./components/Admin/Users";
import Courses from "./components/Admin/Courses";
import Inquiries from "./components/Admin/Inquiries";
import NewsletterComplaints from "./components/Admin/Newsletter";
import Testimonials from "./components/Admin/Testimonials";

// Layout component that conditionally renders header and footer
const Layout = ({ children }) => {
  const location = useLocation();
  const isAdminPage =
    location.pathname.startsWith("/admin") || location.pathname === "/login";

  return (
    <>
      {!isAdminPage && <Header />}
      {children}
      {!isAdminPage && <Footer />}
    </>
  );
};

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<CourseHome />} />
          <Route path="/team" element={<Team />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/journal" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/courses/:courseId" element={<CourseDetail />} />
          <Route path="/login" element={<AdminLogin />} />

          {/* Admin Routes */}
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="users" element={<Users />} />
            <Route path="courses" element={<Courses />} />
            <Route path="inquiries" element={<Inquiries />} />
            <Route path="newsletter" element={<NewsletterComplaints />} />
            <Route path="testimonials" element={<Testimonials />} />

          </Route>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
