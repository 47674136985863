import React, { useState, useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  Users,
  BookOpen,
  Menu,
  X,
  LogOut,
  MessageSquare,
  NewspaperIcon,
} from "lucide-react";
import Person2 from "@mui/icons-material/Person2";

const AdminLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check authentication on mount and route changes
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("isAuthenticated");
    navigate("/login");
  };

  const menuItems = [
    {
      title: "Users",
      icon: <Users className="w-5 h-5" />,
      path: "/admin/users",
    },
    {
      title: "Courses",
      icon: <BookOpen className="w-5 h-5" />,
      path: "/admin/courses",
    },
    {
      title: "Inquiries",
      icon: <MessageSquare className="w-5 h-5" />,
      path: "/admin/inquiries",
    },
    {
      title: "NewsLetter",
      icon: <NewspaperIcon className="w-5 h-5" />,
      path: "/admin/newsletter",
    },
    {
      title: "Testimonials",
      icon: <Person2 className="w-5 h-5" />,
      path: "/admin/testimonials",
    },
  ];

  return (
    <div className="min-h-screen flex">
      {/* Sidebar */}
      <div
        className={`bg-gray-800 text-white h-screen fixed left-0 ${
          isSidebarOpen ? "w-64" : "w-20"
        } transition-all duration-300 ease-in-out flex flex-col`}
      >
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          {isSidebarOpen && <h1 className="text-xl font-bold">Admin Panel</h1>}
          <button
            onClick={() => setSidebarOpen(!isSidebarOpen)}
            className="p-2 hover:bg-gray-700 rounded-lg"
            aria-label={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
          >
            {isSidebarOpen ? (
              <X className="w-5 h-5" />
            ) : (
              <Menu className="w-5 h-5" />
            )}
          </button>
        </div>

        <nav className="flex-1 mt-6">
          {menuItems.map((item) => {
            const isActive = location.pathname === item.path;
            return (
              <button
                key={item.title}
                onClick={() => navigate(item.path)}
                className={`w-full flex items-center p-4 hover:bg-gray-700 transition-colors ${
                  isActive ? "bg-gray-700" : ""
                }`}
              >
                <span className={`${isActive ? "text-blue-400" : ""}`}>
                  {item.icon}
                </span>
                {isSidebarOpen && (
                  <span className={`ml-4 ${isActive ? "text-blue-400" : ""}`}>
                    {item.title}
                  </span>
                )}
              </button>
            );
          })}
        </nav>

        <div className="border-t border-gray-700 p-4">
          <button
            onClick={handleLogout}
            className="w-full flex items-center p-4 hover:bg-gray-700 transition-colors text-red-400 hover:text-red-300"
          >
            <LogOut className="w-5 h-5" />
            {isSidebarOpen && <span className="ml-4">Logout</span>}
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`flex-1 ${
          isSidebarOpen ? "ml-64" : "ml-20"
        } transition-all duration-300 ease-in-out`}
      >
        <div className="p-8 bg-gray-100 min-h-screen">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
