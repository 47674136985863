import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import SchoolIcon from "@mui/icons-material/School";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { countries } from "../../../dummydata";
import "./EnquiryPopup.css";

const EnquiryPopup = ({ open, handleClose, selectedCourse = "" }) => {
  const [courses, setCourses] = useState([]);
  console.log(courses);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    email: "",
    phone: "",
    interest: "",
    message: "",
    learningMode: "online",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch courses when component mounts
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(
          "https://api.accordenglish.in/courses/minimal"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const data = await response.json();
        setCourses(data);
      } catch (err) {
        console.error("Error fetching courses:", err);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      setFormData((prev) => ({
        ...prev,
        interest: selectedCourse,
      }));
    }
  }, [selectedCourse]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (selectedCourse) {
      const course = courses.find((course) => course.title === selectedCourse);
      if (course) {
        setFormData((prev) => ({
          ...prev,
          interest: course.id,
        }));
      }
    }
  }, [selectedCourse, courses]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Find the selected course to get its ID
    const selectedCourseObj = courses.find(
      (course) => course.id === formData.interest
    );

    const apiData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      country: formData.country,
      selected_course_id: formData.interest, // Use the ID directly
      email: formData.email,
      phone_number: formData.phone,
      preferred_learning_mode: formData.learningMode,
      message: formData.message,
      status: "pending",
    };

    try {
      const response = await fetch(
        "https://api.accordenglish.in/inquiries/inquiries/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit inquiry");
      }

      handleClose();
    } catch (error) {
      console.error("Error submitting inquiry:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      className="enquiry-dialog"
      PaperProps={{
        elevation: 0,
        className: "dialog-paper",
      }}
    >
      <Paper className="dialog-header">
        <Box className="header-content">
          <SchoolIcon className="header-icon" />
          <Box>
            <Typography variant="h5" className="header-title">
              Start Your Learning Journey
            </Typography>
            <Typography variant="subtitle1" className="header-subtitle">
              Fill out the form below and we'll help you achieve your goals
            </Typography>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </Paper>

      <form onSubmit={handleSubmit}>
        <DialogContent className="dialog-content">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                className="form-field"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                className="form-field"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                name="country"
                label="Country"
                value={formData.country}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                className="form-field"
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                name="interest"
                label="Selected Course"
                value={formData.interest}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                className="form-field"
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                className="form-field"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="phone"
                label="Phone Number"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                className="form-field"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Preferred Learning Mode
                </FormLabel>
                <RadioGroup
                  row
                  name="learningMode"
                  value={formData.learningMode}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="online"
                    control={<Radio />}
                    label="Online Learning"
                  />
                  <FormControlLabel
                    value="offline"
                    control={<Radio />}
                    label="Offline Learning"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="message"
                label="Your Message"
                value={formData.message}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                className="form-field message-field"
                placeholder="Tell us about your learning goals..."
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="dialog-actions">
          <Button
            onClick={handleClose}
            className="cancel-button"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            className="submit-button"
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <SendIcon />
              )
            }
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Enquiry"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EnquiryPopup;
