import React from "react";
import Back from "../common/back/Back";
import CoursesCard from "./CoursesCard";

const CourseHome = () => {
  return (
    <>
      <section className="back"></section>
      <div className="margin-courses"></div>
      <CoursesCard />
    </>
  );
};

export default CourseHome;
