import React, { useState, useEffect } from "react";
import { LoaderCircle, Edit, Trash2, Eye, Plus } from "lucide-react";
import AddInquiryForm from "./AddInquiryForm";

const Inquiries = () => {
  const [inquiries, setInquiries] = useState([]);
  const [courses, setCourses] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchInquiries();
    fetchCourses();
  }, []);

  const fetchInquiries = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(
        "https://api.accordenglish.in/inquiries/inquiries/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch inquiries");
      const data = await response.json();
      setInquiries(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCourses = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch("https://api.accordenglish.in/courses/", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to fetch courses");
      const data = await response.json();
      const coursesMap = {};
      data.forEach((course) => {
        coursesMap[course.id] = course.title;
      });
      setCourses(coursesMap);
    } catch (err) {
      console.error("Error fetching courses:", err);
    }
  };
  const handleAddOrUpdateInquiry = async (inquiryData) => {
    try {
      const token = localStorage.getItem("access_token");
      let url = "https://api.accordenglish.in/inquiries/inquiries/";

      // If it's an update (PUT) request, append the ID and status as a query parameter
      if (inquiryData.id) {
        url = `https://api.accordenglish.in/inquiries/inquiries/${inquiryData.id}?status=${inquiryData.status}`;
      }

      console.log("Request URL:", url); // For debugging

      const response = await fetch(url, {
        method: inquiryData.id ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inquiryData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.detail ||
            `Failed to ${inquiryData.id ? "update" : "create"} inquiry`
        );
      }

      await fetchInquiries();
      setIsModalOpen(false);
      setSelectedInquiry(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteInquiry = async (id) => {
    if (!window.confirm("Are you sure you want to delete this inquiry?"))
      return;

    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(`https://api.accordenglish.in/inquiries/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to delete inquiry");
      await fetchInquiries();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleUpdateStatus = async (inquiryId, newStatus) => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(
        `https://api.accordenglish.in/inquiries/inquiries/${inquiryId}?status=${newStatus}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: newStatus }),
        }
      );

      if (!response.ok) throw new Error("Failed to update inquiry status");
      await fetchInquiries();
    } catch (err) {
      setError(err.message);
    }
    setIsViewModalOpen(false);
  };

  const ViewInquiryModal = ({ inquiry, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6 space-y-4">
          <div className="flex justify-between items-center border-b pb-4">
            <h2 className="text-xl font-semibold">Inquiry Details</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              ×
            </button>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="font-medium text-gray-700">Name</label>
              <p>
                {inquiry.first_name} {inquiry.last_name}
              </p>
            </div>
            <div>
              <label className="font-medium text-gray-700">Email</label>
              <p>{inquiry.email}</p>
            </div>
            <div>
              <label className="font-medium text-gray-700">Phone</label>
              <p>{inquiry.phone_number}</p>
            </div>
            <div>
              <label className="font-medium text-gray-700">Country</label>
              <p>{inquiry.country}</p>
            </div>
            <div>
              <label className="font-medium text-gray-700">Course</label>
              <p>{courses[inquiry.selected_course_id] || "Unknown Course"}</p>
            </div>
            <div>
              <label className="font-medium text-gray-700">Learning Mode</label>
              <p className="capitalize">{inquiry.preferred_learning_mode}</p>
            </div>
            <div>
              <label className="font-medium text-gray-700">Status</label>
              <select
                value={inquiry.status}
                onChange={(e) => handleUpdateStatus(inquiry.id, e.target.value)}
                className="mt-1 block w-full p-2 border rounded-md"
              >
                <option value="pending">Pending</option>
                <option value="in_progress">In Progress</option>
                <option value="rejected">Rejected</option>
                <option value="completed">Completed</option>
              </select>
            </div>
            <div>
              <label className="font-medium text-gray-700">Created At</label>
              <p>{new Date(inquiry.created_at).toLocaleString()}</p>
            </div>
          </div>

          <div>
            <label className="font-medium text-gray-700">Message</label>
            <p className="mt-1 whitespace-pre-wrap">{inquiry.message}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const getStatusColor = (status) => {
    const colors = {
      new: "bg-blue-100 text-blue-800",
      in_progress: "bg-yellow-100 text-yellow-800",
      contacted: "bg-purple-100 text-purple-800",
      completed: "bg-green-100 text-green-800",
      cancelled: "bg-red-100 text-red-800",
    };
    return colors[status] || "bg-gray-100 text-gray-800";
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <LoaderCircle className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">Inquiries</h2>
        {/* <button
          onClick={() => {
            setSelectedInquiry(null);
            setIsModalOpen(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Inquiry
        </button> */}
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg">
          <p>Error: {error}</p>
        </div>
      )}

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Course
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Learning Mode
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created At
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {inquiries.map((inquiry) => (
                <tr key={inquiry.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {inquiry.first_name} {inquiry.last_name}
                    </div>
                    <div className="text-sm text-gray-500">{inquiry.email}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {courses[inquiry.selected_course_id] || "Unknown Course"}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 capitalize">
                      {inquiry.preferred_learning_mode}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(
                        inquiry.status
                      )}`}
                    >
                      {inquiry.status?.replace("_", " ")}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(inquiry.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                    <div className="flex justify-center space-x-3">
                      <button
                        onClick={() => {
                          setSelectedInquiry(inquiry);
                          setIsViewModalOpen(true);
                        }}
                        className="text-blue-600 hover:text-blue-900"
                        title="View Details"
                      >
                        <Edit className="w-5 h-5" />
                      </button>

                      <button
                        onClick={() => handleDeleteInquiry(inquiry.id)}
                        className="text-red-600 hover:text-red-900"
                        title="Delete Inquiry"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isModalOpen && (
        <AddInquiryForm
          onClose={() => {
            setIsModalOpen(false);
            setSelectedInquiry(null);
          }}
          onSubmit={handleAddOrUpdateInquiry}
          initialData={selectedInquiry}
        />
      )}

      {isViewModalOpen && selectedInquiry && (
        <ViewInquiryModal
          inquiry={selectedInquiry}
          onClose={() => {
            setIsViewModalOpen(false);
            setSelectedInquiry(null);
          }}
        />
      )}
    </div>
  );
};

export default Inquiries;
