import React, { useState, useEffect } from "react";
import { Star, ChevronLeft, ChevronRight, Quote } from "lucide-react";

const TestimonialCard = ({ testimonial, isVisible }) => (
  <div
    className={`transform transition-all duration-500 ease-in-out ${
      isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
    } flex flex-col items-center p-6 mx-auto max-w-3xl`}
  >
    <div className="relative mb-8">
      <img
        src={testimonial.picture_url || "/api/placeholder/96/96"}
        alt="Testimonial author"
        className="w-24 h-24 rounded-full object-cover border-4 border-blue-500 transition-transform duration-300 hover:scale-105"
      />
      <div className="absolute -right-2 -bottom-2 bg-blue-500 rounded-full p-2">
        <Quote className="w-4 h-4 text-white" />
      </div>
    </div>

    <div className="flex gap-1 mb-4">
      {[...Array(testimonial.stars || 5)].map((_, i) => (
        <Star
          key={i}
          className="w-5 h-5 text-yellow-400 fill-current transition-transform duration-300 hover:scale-110"
        />
      ))}
    </div>

    <p className="text-lg text-center text-gray-700 mb-6 italic">
      "{testimonial.matter}"
    </p>

    <h3 className="text-xl font-bold text-gray-900 mb-1">
       {testimonial.name}
    </h3>
    <p className="text-sm text-gray-500">
      {new Date(testimonial.created_at).toLocaleDateString()}
    </p>
  </div>
);

const ModernTestimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch('https://api.accordenglish.in/testimonials/?page=1&page_size=10');
        const data = await response.json();
        setTestimonials(data.items);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    let interval;
    if (isAutoPlay && testimonials.length > 0) {
      interval = setInterval(() => {
        handleNext();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlay, currentIndex, testimonials.length]);

  const handlePrevious = () => {
    setIsAutoPlay(false);
    setIsVisible(false);
    setTimeout(() => {
      setCurrentIndex(
        (prev) => (prev - 1 + testimonials.length) % testimonials.length
      );
      setIsVisible(true);
    }, 300);
  };

  const handleNext = () => {
    setIsAutoPlay(false);
    setIsVisible(false);
    setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % testimonials.length);
      setIsVisible(true);
    }, 300);
  };

  const handleDotClick = (index) => {
    setIsAutoPlay(false);
    setIsVisible(false);
    setTimeout(() => {
      setCurrentIndex(index);
      setIsVisible(true);
    }, 300);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  if (testimonials.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <p className="text-gray-500">No testimonials available</p>
      </div>
    );
  }

  return (
    <div className="relative bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <div className="relative overflow-hidden min-h-[400px]">
          <TestimonialCard
            testimonial={testimonials[currentIndex]}
            isVisible={isVisible}
          />
        </div>

        {testimonials.length > 1 && (
          <div className="flex justify-center items-center gap-4 mt-8">
            <button
              onClick={handlePrevious}
              className="p-2 rounded-full bg-blue-500 text-white transition-all duration-300 hover:bg-blue-600 hover:shadow-lg transform hover:-translate-x-1"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>

            <div className="flex gap-2">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleDotClick(index)}
                  className={`w-3 h-3 rounded-full transition-all duration-300 transform hover:scale-110 ${
                    index === currentIndex
                      ? "bg-blue-500 scale-110"
                      : "bg-gray-300 hover:bg-blue-300"
                  }`}
                />
              ))}
            </div>

            <button
              onClick={handleNext}
              className="p-2 rounded-full bg-blue-500 text-white transition-all duration-300 hover:bg-blue-600 hover:shadow-lg transform hover:translate-x-1"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModernTestimonial;