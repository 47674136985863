import React from "react";
import { Link } from "react-router-dom";
import Heading from "../../common/heading/Heading";
import "./Hero.css";

const Hero = () => {
  const handleButtonClick = () => {
    console.log("View Course button clicked");
  };

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <Heading
              subtitle="WELCOME TO ACCORD"
              title="Best Language Education Expertise"
            />
            <p>
              Your complete language learning journey starts here. Access our
              integrated global platform featuring specialized tutors,
              comprehensive test preparation, and strategic learning
              methodologies - all designed to deliver an exceptional English
              learning experience.
            </p>
            <div className="button">
              <Link to="/courses">
                <button
                  className="primary-btn"
                  style={{ cursor: "pointer" }}
                  onClick={handleButtonClick}
                >
                  VIEW COURSE <i className="fa fa-long-arrow-alt-right"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Hero;
