import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EnquiryPopup from "../common/header/EnquiryPopup";
import Image from "../../Assets/image1.jpeg";
import ModernTestimonial from "../../Testimonial";

const CourseDetail = () => {
  const { courseId } = useParams();
  const [openEnquiry, setOpenEnquiry] = useState(false);
  const [selectedMode, setSelectedMode] = useState("offline");
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://api.accordenglish.in/courses/${courseId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch course details");
        }
        const data = await response.json();
        setCourse(data);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching course details:", err);
      } finally {
        setLoading(false);
      }
    };

    if (courseId) {
      fetchCourseDetails();
    }
  }, [courseId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading course details...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  if (!course) return <div className="p-8 text-center">Course not found</div>;

  const modes = ["online", "offline"];

  return (
    <>
      <section className="back"></section>
      <div className="margin-courses"></div>
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="grid md:grid-cols-2 gap-8">
              <div className="p-8">
                <h1 className="text-4xl font-bold text-gray-900 mb-4">
                  {course.title}
                </h1>
                <p className="text-xl text-blue-600 mb-4">{course.subtitle}</p>
                <p className="text-gray-600 mb-6">{course.short_description}</p>

                {/* Mode Selection */}
                <div className="mb-6">
                  <p className="text-gray-700 mb-2">Select Mode of Learning:</p>
                  <div className="bg-gray-100 rounded-lg p-2 inline-flex">
                    {modes.map((mode) => (
                      <button
                        key={mode}
                        className={`px-4 py-2 rounded-md ${
                          selectedMode === mode
                            ? "bg-blue-600 text-white"
                            : "text-gray-600 hover:bg-gray-200"
                        } transition-colors`}
                        onClick={() => setSelectedMode(mode)}
                      >
                        {mode.charAt(0).toUpperCase() + mode.slice(1)}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="bg-blue-50 p-4 rounded-lg mb-6">
                  <p className="text-blue-800 font-semibold mb-2">
                    Duration: {course.duration[selectedMode]}
                  </p>
                  <p className="text-blue-800 font-semibold">
                    Batch Size: {course.batch_size[selectedMode]}
                  </p>
                </div>
                <button
                  onClick={() => setOpenEnquiry(true)}
                  className="w-full md:w-auto px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors font-medium"
                >
                  Enquire Now
                </button>
              </div>
              <div className="relative h-64 md:h-auto">
                {/* Using a placeholder image since image URL is not provided in API response */}
                <img
                  src={Image}
                  alt={course.title}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          {/* Course Features */}
          <div className="mt-12 grid md:grid-cols-2 gap-8">
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Course Highlights
              </h2>
              <ul className="space-y-4">
                {course.course_highlights[selectedMode].map(
                  (highlight, index) => (
                    <li key={index} className="flex items-start">
                      <span className="text-blue-500 mr-3">✓</span>
                      <span className="text-gray-700">{highlight}</span>
                    </li>
                  )
                )}
              </ul>
            </div>

            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                What You'll Get
              </h2>
              <ul className="space-y-4">
                {course.what_youll_get[selectedMode].map((feature, index) => (
                  <li key={index} className="flex items-start">
                    <span className="text-blue-500 mr-3">•</span>
                    <span className="text-gray-700">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <ModernTestimonial />
        <EnquiryPopup
          open={openEnquiry}
          handleClose={() => setOpenEnquiry(false)}
          selectedCourse={`${course.title} (${selectedMode})`}
        />
      </div>
    </>
  );
};

export default CourseDetail;
