import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Head from "./Head";
import "./header.css";
import EnquiryPopup from "./EnquiryPopup";

const Header = () => {
  const [click, setClick] = useState(false);
  const [openEnquiry, setOpenEnquiry] = useState(false);
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  const handleOpenEnquiry = () => setOpenEnquiry(true);
  const handleCloseEnquiry = () => setOpenEnquiry(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(
          "https://api.accordenglish.in/courses/minimal"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const data = await response.json();
        setCourses(data);
      } catch (err) {
        console.error("Error fetching courses:", err);
      }
    };

    fetchCourses();
  }, []);

  const handleCourseClick = (courseId) => {
    navigate(`/courses/${courseId}`);
    setClick(false);
  };

  return (
    <>
      <Head />
      <header>
        <nav className="flexSB">
          <ul className={click ? "mobile-nav" : "flexSB"}>
            <li>
              <Link to="/" onClick={() => setClick(false)}>
                Home
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/courses">Courses</Link>
              <div className="dropdown-content">
                {courses.map((course) => (
                  <li key={course.id}>
                    <Link
                      to={`/courses/${course.id}`}
                      onClick={() => handleCourseClick(course.id)}
                      className="course-link"
                    >
                      <div className="course-info">
                        <span className="course-name">{course.title}</span>
                      </div>
                    </Link>
                  </li>
                ))}
              </div>
            </li>
            <li>
              <Link to="/about" onClick={() => setClick(false)}>
                About
              </Link>
            </li>
            <li>
              <Link to="/pricing" onClick={() => setClick(false)}>
                FAQ's
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={() => setClick(false)}>
                Contact
              </Link>
            </li>
          </ul>
          <div
            onClick={handleOpenEnquiry}
            style={{ cursor: "pointer" }}
            className="start"
          >
            <div className="button">Quick Enquiry</div>
          </div>
          <button className="toggle" onClick={() => setClick(!click)}>
            {click ? (
              <i className="fa fa-times"> </i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button>
        </nav>
      </header>

      <EnquiryPopup open={openEnquiry} handleClose={handleCloseEnquiry} />
    </>
  );
};

export default Header;
