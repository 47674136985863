import React, { useState, useEffect } from "react";
import {
  Plus,
  Edit,
  Trash2,
  LoaderCircle,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import AddCourseForm from "./AddCourseForm";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [editingCourse, setEditingCourse] = useState(null);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch("https://api.accordenglish.in/courses/", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to fetch courses");
      const data = await response.json();
      setCourses(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddOrUpdateCourse = async (courseData) => {
    try {
      const token = localStorage.getItem("access_token");
      const url = editingCourse
        ? `https://api.accordenglish.in/courses/${editingCourse.id}`
        : "https://api.accordenglish.in/courses/";

      const response = await fetch(url, {
        method: editingCourse ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(courseData),
      });

      if (!response.ok)
        throw new Error(
          `Failed to ${editingCourse ? "update" : "create"} course`
        );
      await fetchCourses();
      handleCloseModal();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteCourse = async (id) => {
    if (!window.confirm("Are you sure you want to delete this course?")) return;

    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(`https://api.accordenglish.in/courses/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to delete course");
      await fetchCourses();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingCourse(null);
  };

  const handleEditClick = (course) => {
    setEditingCourse(course);
    setIsModalOpen(true);
  };

  const toggleRow = (id) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const renderModeSection = (data, mode) => {
    if (!data[mode]) return null;
    return (
      <div className="space-y-2">
        <div className="grid grid-cols-1 gap-4">
          <div className="pb-2">
            {Array.isArray(data[mode]) ? (
              <ul className="list-disc ml-5 space-y-1">
                {data[mode].map((item, i) => (
                  <li key={i} className="text-sm text-gray-600">
                    {item}
                  </li>
                ))}
              </ul>
            ) : (
              <span className="text-sm text-gray-600">{data[mode]}</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderExpandedContent = (course) => {
    return (
      <div className="px-6 py-4 bg-gray-50">
        <div className="mb-6">
          <h4 className="font-medium text-gray-900 mb-2">Description</h4>
          <p className="text-sm text-gray-600">{course.short_description}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Online Content */}
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-blue-600 border-b pb-2">
              Online Details
            </h3>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">Duration</h4>
              {renderModeSection(course.duration, "online")}
            </div>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">Batch Size</h4>
              {renderModeSection(course.batch_size, "online")}
            </div>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">
                Course Highlights
              </h4>
              {renderModeSection(course.course_highlights, "online")}
            </div>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">
                What You'll Get
              </h4>
              {renderModeSection(course.what_youll_get, "online")}
            </div>
          </div>

          {/* Offline Content */}
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-green-600 border-b pb-2">
              Offline Details
            </h3>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">Duration</h4>
              {renderModeSection(course.duration, "offline")}
            </div>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">Batch Size</h4>
              {renderModeSection(course.batch_size, "offline")}
            </div>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">
                Course Highlights
              </h4>
              {renderModeSection(course.course_highlights, "offline")}
            </div>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">
                What You'll Get
              </h4>
              {renderModeSection(course.what_youll_get, "offline")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <LoaderCircle className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">Courses</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Course
        </button>
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg">
          <p>Error: {error}</p>
        </div>
      )}

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="w-10 px-6 py-3"></th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Title
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Mode
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {courses.map((course) => (
                <React.Fragment key={course.id}>
                  <tr className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      <button
                        onClick={() => toggleRow(course.id)}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        {expandedRows.has(course.id) ? (
                          <ChevronUp className="w-5 h-5" />
                        ) : (
                          <ChevronDown className="w-5 h-5" />
                        )}
                      </button>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-col">
                        <span className="font-medium text-gray-900">
                          {course.title}
                        </span>
                        <span className="text-sm text-gray-500">
                          {course.subtitle}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {course.mode_of_learning.replace("_", " & ")}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      <div className="flex justify-center space-x-3">
                        <button
                          onClick={() => handleEditClick(course)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          <Edit className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteCourse(course.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                  {expandedRows.has(course.id) && (
                    <tr>
                      <td colSpan="4">{renderExpandedContent(course)}</td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isModalOpen && (
        <AddCourseForm
          onClose={handleCloseModal}
          onSubmit={handleAddOrUpdateCourse}
          initialData={editingCourse}
        />
      )}
    </div>
  );
};

export default Courses;
