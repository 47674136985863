import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Feature data
const features = [
  {
    title: "Fast",
    description: "Simplified, Collated and Balanced Training Scheme",
    icon: "⚡",
  },
  {
    title: "Flexible",
    description: "International, Specialized and Cohesive Training Schemes",
    icon: "🔄",
  },
  {
    title: "Fair",
    description:
      "Neutral Training, Clarified Materials and Individual Assistance",
    icon: "⚖️",
  },
  {
    title: "Reliable",
    description:
      "Proactive, Independent, Interactive and Integrated Learning Environment",
    icon: "🎯",
  },
];

const FeatureCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease-in-out",
  background: theme.palette.background.paper,
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: theme.shadows[8],
  },
}));

const StyledCardContent = styled(CardContent)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "24px !important", // Override default padding
  height: "100%",
});

const IconBox = styled(Box)(({ theme }) => ({
  fontSize: "2.5rem",
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "80px",
  height: "80px",
  borderRadius: "50%",
  color: theme.palette.primary.contrastText,
  flexShrink: 0, // Prevent icon from shrinking
}));

const WhyChooseUs = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.2,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        py: 8,
        background: (theme) => theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        {/* Header Section */}
        <Box
          sx={{
            textAlign: "center",
            mb: 6,
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? "translateY(0)" : "translateY(20px)",
            transition: "all 0.6s ease-out",
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Why Choose Us
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{
              maxWidth: "800px",
              mx: "auto",
              lineHeight: 1.8,
              mb: 4,
            }}
          >
            We bring premium and assorted preparation scheme for language
            training in English with the specialist training faculty alongside
            the latest materials, just to bring the right mix in English
            language training.
          </Typography>
        </Box>

        {/* Features Grid */}
        <Grid container spacing={4} alignItems="stretch">
          {features.map((feature, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              sx={{ display: "flex" }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? "translateY(0)" : "translateY(30px)",
                  transition: `all 0.6s ease-out ${index * 0.1}s`,
                }}
              >
                <FeatureCard elevation={2}>
                  <StyledCardContent>
                    <IconBox>{feature.icon}</IconBox>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        mt: 2,
                        color: "primary.main",
                        flexShrink: 0,
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{
                        lineHeight: 1.7,
                        mt: 1,
                        textAlign: "center",
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </StyledCardContent>
                </FeatureCard>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default WhyChooseUs;
