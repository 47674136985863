import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./courses.css";
import EnquiryPopup from "../common/header/EnquiryPopup";
import Heading from "../common/heading/Heading";
import Image from "../../Assets/image1.jpeg";

const CoursesCard = () => {
  const [openEnquiry, setOpenEnquiry] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setLoading(true);
        const response = await fetch("https://api.accordenglish.in/courses");
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const data = await response.json();
        setCourses(data);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching courses:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const handleEnrollClick = (course) => {
    setSelectedCourse(course.title);
    setOpenEnquiry(true);
  };

  const handleCloseEnquiry = () => {
    setOpenEnquiry(false);
  };

  const handleCourseClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  const renderDuration = (course) => {
    return (
      <div className="flex flex-col gap-2 bg-gray-50 p-3 rounded-md">
        <div className="flex items-center gap-2">
          <span className="text-blue-600 font-medium min-w-16">Online:</span>
          <span className="text-gray-600">{course.duration.online}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-blue-600 font-medium min-w-16">Offline:</span>
          <span className="text-gray-600">{course.duration.offline}</span>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading courses...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  return (
    <>
      <section className="coursesCard">
        <Heading subtitle="COURSES" title="Browse Our Courses" />

        <div className="container grid2">
          {courses.map((course) => (
            <div className="items" key={course.id}>
              <div className="content flex">
                <div className="left">
                  <div className="img">
                    <img
                      src={Image}
                      alt={course.title}
                      className="cursor-pointer"
                      onClick={() => handleCourseClick(course.id)}
                    />
                  </div>
                </div>
                <div className="text">
                  <h1
                    className="cursor-pointer hover:text-blue-600 mb-2"
                    onClick={() => handleCourseClick(course.id)}
                  >
                    {course.title}
                  </h1>
                  <div className="rate mb-3">
                    {[...Array(5)].map((_, index) => (
                      <i key={index} className="fa fa-star"></i>
                    ))}
                    <label>(5.0)</label>
                  </div>
                  <div className="details">
                    <div className="box">
                      <div className="para">
                        <p className="text-gray-600 mb-4">
                          {course.short_description}
                        </p>
                      </div>
                    </div>
                    <div className="duration-info">
                      {renderDuration(course)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 flex justify-between items-center px-4">
                <button
                  className="outline-btn"
                  onClick={() => handleEnrollClick(course)}
                >
                  ENROLL NOW !
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      <EnquiryPopup
        open={openEnquiry}
        handleClose={handleCloseEnquiry}
        selectedCourse={selectedCourse}
      />
    </>
  );
};

export default CoursesCard;
